import React from "react"
import Layout from "../components/layout/layout"
import Hero from "../components/layout/hero"
import Navbar from "../components/layout/navbar"
import { graphql } from "gatsby"
import SEO from "../components/seo"

export default ({ data }) => {
  return (
  <Layout>
    <SEO
      title="Contact Us | Caledonian Tree Services"
      canonicalUrl={data.site.siteMetadata.siteUrl + "/contact/"}
      description=""
    />
    <Hero
      hero={data.hero.desktopImage.fluid}
      heroMobile={data.heroMobile.mobileImage.fluid}
      logo={data.logo.childImageSharp.fixed}
      arb={data.arb.childImageSharp.fixed}
      strapline="Scotland’s Premier Arboriculture & <br /> Commercial Tree Surgeon"
      straplineMobile="Scotland’s Premier Arboriculture<br /> & Commercial Tree Surgeon"/>
    <Navbar/>
    <section className="section">
      <div className="container content-container">
        <div className="columns">
          <div className="column">
            <h1 className="title is-size-5 is-size-6-mobile">Contact us here at Caledonian Tree Services</h1>
            <p>Please feel free to contact us using the details below, or simply fill out our contact form and we'll get back to you as soon as poosible.</p><br />
            <hr /><br />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h2>Caledonian Tree Services</h2>
            <p>South Craigmarloch<br />
              Port Glasgow Road<br />
              Kilmalcolm<br />
              PA13 4SG<br />
              Tel : 01505 872929 / 07801 277735<br />
              Fax : 01505 872929</p><br />
              <hr />
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container content-container">
        <form className="contactForm" name="contact" method="POST" data-netlify="true" encType="multipart/form-data" action="/contact-success/">
          <div className="columns">
            <div className="column is-one-third-desktop is-full-tablet">
                <label htmlFor="firstName">First Name<span aria-hidden="true" role="presentation" className="field_required">*</span></label><br />
                <input id="firstName" type="text" name="firstName" required />
            </div>
            <div className="column is-one-third-desktop is-full-tablet">
              <label htmlFor="lastName">Last Name<span aria-hidden="true" role="presentation" className="field_required">*</span></label><br />
              <input id="lastName" type="text" name="lastName" required/>
            </div>
            <div className="column is-one-third-desktop is-full-tablet">
              <label htmlFor="email">Email<span aria-hidden="true" role="presentation" className="field_required">*</span></label><br />
              <input id="email" type="email" name="email" required/>
            </div>
          </div>
          <div className="columns">
            <div className="column is-full">
              <label htmlFor="phone">Contact number<span aria-hidden="true" role="presentation" className="field_required">*</span></label><br />
              <input id="phone" type="number" name="phone" required/>
            </div>
          </div>
          <div className="columns">
            <div className="column is-full">
              <label htmlFor="area">Area of Scotland</label><br />
              <input id="area" type="text" name="area"/>
            </div>
          </div>
          <div className="columns">
            <div className="column is-full">
              <label htmlFor="findUs">Where did you find us?</label><br />
              <input id="findUs" type="text" name="findUs"/>
            </div>
          </div>
          <div className="columns">
            <div className="column is-full">
              <h1 className="title is-size-5 is-size-6-mobile">How can we help?</h1>
              <p>Feel free to ask a question or simply leave a comment.</p><br />
            </div>
          </div>
          <div className="columns">
            <div className="column is-full">
              <label htmlFor="enquiry">Comments / Questions<span aria-hidden="true" role="presentation" className="field_required">*</span></label><br/>
              <textarea id="enquiry" type="text" name="enquiry" required/>
            </div>
          </div>
          <button type="submit" className="btn">Send Enquiry</button>
          <input type="hidden" name="form-name" value="contact" />
        </form>
      </div>
    </section>
  </Layout>
)}

export const query = graphql`
  query {
    hero: contentfulHeroImage(desktopImage: {title: {eq: "contact-hero"}}) {
      desktopImage{
        fluid{
          src
        }
      }
    }
    heroMobile: contentfulHeroImage(mobileImage: {title: {eq: "contact-hero-mobile"}}) {
      mobileImage {
        fluid {
          src
        }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mulch: file(relativePath: { eq: "index/mulch.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sectional: file(relativePath: { eq: "index/sectional.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tractor: file(relativePath: { eq: "index/tractor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logs: file(relativePath: { eq: "index/logs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    arb: file(relativePath: { eq: "ARB-Approved2.png" }) {
      childImageSharp {
        fixed(width: 125, height: 150) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
